/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* If the user prefers dark mode, 
we of course apply color-scheme: dark, as usual */
@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

/* If the user prefers light mode, 
we still enforce color-scheme: dark, despite the user preference */
@media (prefers-color-scheme: light) {
  html {
    color-scheme: dark;
  }
}

@layer base {
  html,
  body {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    font-display: optional;
    @apply min-h-[600px];
    @apply min-w-80;
    @apply bg-gray-100 dark:bg-darkmode-900 font-inter;
    scroll-padding-top: 80px;
  }
  a {
    @apply text-yellow-500 !important;
  }
  .whiteanchor {
    a {
      @apply text-white !important;
    }
  }
  @font-face {
    font-family: "jetbrains";
    src: url("/public/fonts/JetBrainsMono/JetBrainsMono-VariableFont_wght.ttf");
    font-weight: 100;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "jetbrains";
    src: url("/public/fonts/JetBrainsMono/JetBrainsMono-ExtraLight.ttf");
    font-weight: 200;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "jetbrains";
    src: url("/public/fonts/JetBrainsMono/JetBrainsMono-Light.ttf");
    font-weight: 300;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "jetbrains";
    src: url("/public/fonts/JetBrainsMono/JetBrainsMono-Medium.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "jetbrains";
    src: url("/public/fonts/JetBrainsMono/JetBrainsMono-Regular.ttf");
    font-weight: 500;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "jetbrains";
    src: url("/public/fonts/JetBrainsMono/JetBrainsMono-SemiBold.ttf");
    font-weight: 600;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "jetbrains";
    src: url("/public/fonts/JetBrainsMono/JetBrainsMono-Bold.ttf");
    font-weight: 700;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "inter";
    src: url("/public/fonts/Inter/Inter-ExtraLight.ttf");
    font-weight: 100;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "inter";
    src: url("/public/fonts/Inter/Inter-Light.ttf");
    font-weight: 200;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "inter";
    src: url("/public/fonts/Inter/Inter-Medium.ttf");
    font-weight: 300;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "inter";
    src: url("/public/fonts/Inter/Inter-Regular.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "inter";
    src: url("/public/fonts/Inter/Inter-SemiBold.ttf");
    font-weight: 500;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "inter";
    src: url("/public/fonts/Inter/Inter-Bold.ttf");
    font-weight: 600;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "inter";
    src: url("/public/fonts/Inter/Inter-ExtraBold.ttf");
    font-weight: 700;
    font-style: normal;
    font-display: block;
  }
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 13px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #fafafa;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #999999;
    border-radius: 100vh;
    border: 2.5px solid #fafafa;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #b0b0b0;
  }
}

@layer utilities {
  .darkscrollbar::-webkit-scrollbar {
    width: 10px;
  }

  .darkscrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #101010;
  }

  .darkscrollbar::-webkit-scrollbar-thumb {
    background: #1c1c1c;
    border-radius: 100vh;
    border: 2.5px solid #1c1c1c;
  }

  .darkscrollbar::-webkit-scrollbar-thumb:hover {
    background: #282828;
  }
}

@layer utilities {
  .bg-darkmode-900 {
    @apply bg-gradient-to-r from-newdarkmode-800 to-newdarkmode-700;
  }
  .bg-darkmode-900 {
    @apply bg-gradient-to-r from-newdarkmode-900 to-newdarkmode-800;
  }
  .bg-darkmode-900down {
    @apply bg-gradient-to-b from-newdarkmode-800 to-newdarkmode-700;
  }
  .lightbg-darkmode-900 {
    @apply bg-gradient-to-r from-gray-300 to-gray-200;
  }
}

.without-ring {
  @apply focus:ring-0 focus:ring-offset-0;
}

datalist#markers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  writing-mode: horizontal-tb;
  height: 10px;
}

/* === range theme and appearance === */
input[type="range"] {
  font-size: 1.5rem;
}

input[type="range"] {
  color: #ffcb12;
  --thumb-height: 1.125em;
  --track-height: 0.125em;
  --track-color: rgba(0, 0, 0, 0.2);
  --brightness-hover: 180%;
  --brightness-down: 80%;
  --clip-edges: 0.125em;
}

input[type="range"].win10-thumb {
  color: #2b2d42;

  --thumb-height: 1.375em;
  --thumb-width: 0.5em;
  --clip-edges: 0.0125em;
}

@media (prefers-color-scheme: dark) {
  html {
    background-color: #000;
  }

  html::before {
    background: radial-gradient(circle at center, #101112, #000);
  }

  input[type="range"] {
    color: #ffcb12;
    --track-color: rgba(255, 255, 255, 0.1);
  }

  input[type="range"].win10-thumb {
    color: #ffcb12;
  }
}

/* === range commons === */
input[type="range"] {
  position: relative;
  background: #fff0;
  overflow: hidden;
}

input[type="range"]:active {
  cursor: grabbing;
}

input[type="range"]:disabled {
  filter: grayscale(1);
  opacity: 0.3;
  cursor: not-allowed;
}

/* === WebKit specific styles === */
input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  transition: all ease 100ms;
  height: var(--thumb-height);
}

input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
  position: relative;
}

input[type="range"]::-webkit-slider-thumb {
  --thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
  --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
  --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
  --clip-further: calc(100% + 1px);
  --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0
    100vmax currentColor;

  width: var(--thumb-width, var(--thumb-height));
  background: linear-gradient(currentColor 0 0) scroll no-repeat left center /
    50% calc(var(--track-height) + 1px);
  background-color: currentColor;
  box-shadow: var(--box-fill);
  border-radius: var(--thumb-width, var(--thumb-height));

  filter: brightness(100%);
  clippath: polygon(
    100% -1px,
    var(--clip-edges) -1px,
    0 var(--clip-top),
    -100vmax var(--clip-top),
    -100vmax var(--clip-bottom),
    0 var(--clip-bottom),
    var(--clip-edges) 100%,
    var(--clip-further) var(--clip-further)
  );
}

input[type="range"]:hover::-webkit-slider-thumb {
  cursor: grab;
}

input[type="range"]:active::-webkit-slider-thumb {
  cursor: grabbing;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center /
    100% calc(var(--track-height) + 1px);
}

input[type="range"]:disabled::-webkit-slider-thumb {
  cursor: not-allowed;
}

/* === Firefox specific styles === */
input[type="range"],
input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb {
  appearance: none;
  transition: all ease 100ms;
  height: var(--thumb-height);
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb,
input[type="range"]::-moz-range-progress {
  background: #fff0;
}

input[type="range"]::-moz-range-thumb {
  background: currentColor;
  border: 0;
  width: var(--thumb-width, var(--thumb-height));
  border-radius: var(--thumb-width, var(--thumb-height));
  cursor: grab;
}

input[type="range"]:active::-moz-range-thumb {
  cursor: grabbing;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  background: var(--track-color);
}

input[type="range"]::-moz-range-progress {
  appearance: none;
  background: currentColor;
  transition-delay: 30ms;
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-progress {
  height: calc(var(--track-height) + 1px);
  border-radius: var(--track-height);
}

input[type="range"]::-moz-range-thumb,
input[type="range"]::-moz-range-progress {
  filter: brightness(100%);
}

input[type="range"]:active::-moz-range-thumb,
input[type="range"]:active::-moz-range-progress {
  filter: brightness(var(--brightness-down));
}

input[type="range"]:disabled::-moz-range-thumb {
  cursor: not-allowed;
}

input[type="checkbox"] {
  color: #ffcb12;
  vertical-align: middle;
  -webkit-appearance: none;
  background: none;
  border: 0;
  outline: 0;
  flex-grow: 0;
  border-radius: 15%;
  background-color: #ffffff;
  transition: background 300ms;
  cursor: pointer;
}

/* Pseudo element for check styling */

input[type="checkbox"]::before {
  content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #ccd3d8;
}

/* Checked */

input[type="checkbox"]:checked {
  background-color: currentcolor;
}

input[type="checkbox"]:checked::before {
  box-shadow: none;
}

/* Disabled */

input[type="checkbox"]:disabled {
  background-color: #ccd3d8;
  opacity: 0.84;
  cursor: not-allowed;
}

/* IE */

input[type="checkbox"]::-ms-check {
  content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #ccd3d8;
}

.swiper-pagination-bullet {
  background-color: white !important;
  display: inline-block;
  margin-top: 2em;
  color: gray !important;
}
.swiper-pagination-bullet-active-next,
.swiper-pagination-bullet-active-next-next {
  background-color: white !important;
}
.swiper-pagination-bullet-active-prev {
  background-color: gray !important;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}
